@import url("https://fonts.googleapis.com/css?family=Noto+Sans&display=swap");
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');
ol.versesList {
    list-style: none;
    /* counter-reset: my-awesome-counter; */
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

ol.versesList li {
    counter-increment: my-awesome-counter;
    display: flex;
    /* width: 50%; */
    /* font-size: 0.8rem; */
    margin-bottom: 0.5rem;
}

ol.versesList li::before {
    content: counter(my-awesome-counter);
    font-weight: bold;
    font-size: 3rem;
    margin-right: 0.5rem;
    font-family: 'Abril Fatface', serif;
    /* font-family: 'Noto Sans', sans-serif; */
    line-height: 1;
}

li>article>p {
    color: #856404;
}

.verse {
    font-family: 'Noto Sans', sans-serif;
}

article {
    width: 100%;
}


.labelNotApproved{
    width: 4rem;
    font-size: 10px;
    background-color: rgb(244, 67, 54);
    color: white;
    font-family: monospace;
    border-radius: 1rem;
    padding-left: 3px;
    padding-right: 3px;
}

.labelApproved {
    font-size: 13px;
    background-color: rgb(139, 195, 74);
    color: white;
    font-family: monospace;
    border-radius: 1rem;
    padding: 2px;
}


@media screen and (max-width: 575px) {
    .labelApproved, .labelNotApproved {
    display: inline-block;
    position: absolute;
    border-radius: 0;
    top: 0;
    left: 40%;
    }
  }
  
  @media screen and (min-width: 300px) and (max-width: 358px) {
    .labelApproved, .labelNotApproved {
    display: inline-block;
    position: absolute;
    border-radius: 0;
    top: 0;
    left: 36%;
    }
  }
  
@media screen and (min-width: 1400px) {
    #aboutUsText{
        font-size: 1.3rem;
    }
  } 

  @media screen and (max-width: 380px) {
    #backButtonVerses{
        display: none!important;
    }

    #backButtonForSmallScreens{
        display: block;
        padding-top: 10px;
    }
  } 

  @media screen and (min-width: 380px) {
    #backButtonVerses{
        display: inline-block!important;
    }

    #backButtonForSmallScreens{
        display: none;
    }
  } 

  .verseContent
    {
        line-height: 38px;
    }

  @media screen and (max-width: 770px) {
    .verseContent
    {
        line-height: 24px;
    }
  } 

  #verselinks::-webkit-scrollbar {
    width: 0.5em;
}
 
#verselinks::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
#verselinks::-webkit-scrollbar-thumb {
  background-color: #cc9e13;
  outline: 1px solid #ffc107;
}
  
.unavailable-verse{
    color: #a09a9a;
    font-style: italic;
}
  /* :target{ */
    /* padding-top:45px; */
    /* margin-top:-45px; */
  /* } */