/* #box-js::-webkit-scrollbar {
            width: 1em;
        }
        
        #box-js::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        
        #box-js::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        } */

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.pali-hover {
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 1em;
}

@media only screen and (max-width: 650px) {
  .pali-hover {
    font-size: 15px !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}

.btn-custom {
  background-color: #856404;
  border-color: #856404;
}

.card-body {
  box-shadow: inset 0 0 60px 30px #ffc, inset 0 0 84px 17px #ffc107,
    inset 0 0 228px 12px #ffc107, 0 0 60px 30px #ffc, 0 0 84px 17px #ffc107,
    0 0 228px 12px #ffc107;
}

.card-body {
  box-shadow: 0 0 0px 0px #b5b527, 0 0 84px 2px #42340c,
    inset 0 0 228px 12px #c1991f, inset 0 0 60px 30px #ffc,
    17px -18px 0px 0px #ffc107, 0 0 228px 12px #ffc107;
}

.card-text::-webkit-scrollbar {
  width: 3px;
}

/* Track */

.card-text::-webkit-scrollbar-track {
  background: white;
}

/* Handle */

.card-text::-webkit-scrollbar-thumb {
  background: #856404;
  border-radius: 5px;
}

/* Handle on hover */

.card-text::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@import url("https://fonts.googleapis.com/css?family=Noto+Sans&display=swap");
.verse {
  font-family: "Noto Sans", sans-serif;
}

.line-clamp {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
}

.line-clamp:after {
  content: "...";
  text-align: right;
  bottom: 0;
  right: 0;
  width: 25%;
  display: block;
  position: absolute;
  height: calc(1em * 1.2);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 75%
  );
}

@supports (-webkit-line-clamp: 1) {
  .line-clamp:after {
    display: none !important;
  }
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  height: calc(1em * 1.2 * 1);
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  height: calc(1em * 1.2 * 2);
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  height: calc(1em * 1.2 * 3);
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
  height: calc(1em * 1.2 * 4);
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
  height: calc(1em * 1.2 * 5);
}

.switch-mode {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  /* background: #333; */
  background: #3f51b5;
  position: fixed;
  top: 0px;
  bottom: 70vh;
  left: 2px;
  margin: auto;
  opacity: 0.7;
  z-index: 2;
}

.switch-mode:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 12px 0px,
    rgba(0, 0, 0, 0.12) 0px 2px 11px 8px;
  transition: 0.5s;
  opacity: 1;
}

/* body {
  background-color: tan;
} */

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .features-icons {
    padding-top: 1rem;
  }
}

/* On screens that are 600px or less, set the background color to olive */
 @media screen and (max-width: 575px) {
  ol.versesList li::before{
    display: none;
    /* font-size: 1rem; */
  }
} 

.insideVerseNumber {
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
  margin-right: 0.5rem;
  font-family: "Abril Fatface";
  line-height: 1;
}

.outsideVerseNumber {
  display: none;
}

@media screen and (min-width: 575px) {
  .insideVerseNumber {
    display: none;
  }

  .outsideVerseNumber {
    display: block;
    font-weight: bold;
    font-size: 3rem;
    margin-right: 0.5rem;
    font-family: 'Abril Fatface', serif;
    line-height: 1;
    float: left;
  }
}

.divider-thingy {
  border-right: 2px solid;
}

@media screen and (max-width: 991px) {
  .divider-thingy {
    border-right: 0px;
    /* border-bottom: 2px solid; */
    margin-bottom: 10px;
  }
}

#mastheadercontent {
  top: 6rem;
}

@media screen and (min-width: 1000px) {
  #mastheadercontent {
    top: 10rem;
  }
}


.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: 56px;
  background-color: rgb(245, 243, 243);
}
.popup\_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}
