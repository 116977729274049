    @import url("https://fonts.googleapis.com/css?family=Lora:400,700,400italic,700italic");
    @import url("https://fonts.googleapis.com/css?family=Cabin:700");
    a:hover {
        color: #b1a721;
        text-decoration: underline
    }
    
    .btn {
        display: inline-block;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
    }
    
    .btn-link:hover {
        color: #21b17b;
        text-decoration: underline
    }
    
    .nav {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none
    }
    
    .nav-link {
        display: block;
        padding: .5rem 1rem
    }
    
    .nav-link:focus,
    .nav-link:hover {
        text-decoration: none
    }
    
    .navbar {
        position: relative;
        padding: .5rem 1rem
    }
    
    .navbar,
    .navbar>.container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
    
    .navbar-brand {
        display: inline-block;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap
    }
    
    .navbar-brand:focus,
    .navbar-brand:hover {
        text-decoration: none
    }
    
    .navbar-nav {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none
    }
    
    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0
    }
    
    .navbar-collapse {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-align: center;
        align-items: center
    }
    
    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: .25rem
    }
    
    .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none
    }
    
    @media (max-width:767.98px) {
        .navbar-expand-md>.container {
            padding-right: 0;
            padding-left: 0
        }
    }
    
    @media (min-width:768px) {
        .navbar-expand-md {
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -ms-flex-pack: start;
            justify-content: flex-start
        }
        .navbar-expand-md .navbar-nav {
            -ms-flex-direction: row;
            flex-direction: row
        }
        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }
        .navbar-expand-md>.container {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap
        }
        .navbar-expand-md .navbar-collapse {
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto;
            flex-basis: auto
        }
        .navbar-expand-md .navbar-toggler {
            display: none
        }

        #changeChapterDropDown{
            margin-right: 10px;
        }
    }
    
    @media (max-width:991.98px) {
        .navbar-expand-lg>.container {
            padding-right: 0;
            padding-left: 0
        }
    }
    
    @media (min-width:992px) {
        .navbar-expand-lg {
            -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
            -ms-flex-pack: start;
            justify-content: flex-start
        }
        .navbar-expand-lg .navbar-nav {
            -ms-flex-direction: row;
            flex-direction: row
        }
        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }
        .navbar-expand-lg>.container {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap
        }
        .navbar-expand-lg .navbar-collapse {
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto;
            flex-basis: auto
        }
        .navbar-expand-lg .navbar-toggler {
            display: none
        }
    }
    
    .navbar-light .navbar-brand,
    .navbar-light .navbar-brand:focus,
    .navbar-light .navbar-brand:hover {
        color: rgba(0, 0, 0, .9)
    }
    
    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, .5)
    }
    
    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(0, 0, 0, .7)
    }
    
    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, .5);
        border-color: rgba(0, 0, 0, .1)
    }

    body {
        font-family: Lora, Helvetica Neue, Helvetica, Arial, sans-serif;
        position: relative;
        color: #fff;
        background-color: #000
    }
    
    body,
    html {
        width: 100%;
        height: 100%
    }
    
    h1 {
        font-family: Cabin, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: 700;
        margin: 0 0 35px;
        letter-spacing: 1px;
        text-transform: uppercase
    }
    
    #mainNav {
        font-family: Cabin, Helvetica Neue, Helvetica, Arial, sans-serif;
        margin-bottom: 0;
        text-transform: uppercase;
        border-bottom: 1px solid hsla(0, 0%, 100%, .3);
        background-color: #000
    }
    
    #mainNav .navbar-toggler {
        font-size: 14px;
        padding: 11px;
        color: #fff;
        border: 1px solid #fff
    }
    
    #mainNav .navbar-brand {
        font-weight: 700
    }
    
    #mainNav a {
        color: #fff
    }
    
    #mainNav .navbar-nav .nav-item {
        transition: background .3s ease-in-out
    }
    
    #mainNav .navbar-nav .nav-item:hover {
        color: fade(#fff, 80%)
    }
    
    .masthead {
        display: table;
        width: 100%;
        height: auto;
        padding: 200px 0;
        text-align: center;
        color: #fff;
    }
    
    .masthead .intro-body {
        display: table-cell;
        vertical-align: middle
    }
    
    .masthead .intro-body .brand-heading {
        font-size: 50px
    }
    
    .masthead .intro-body .intro-text {
        font-size: 18px
    }
    
    @media (min-width:768px) {
        .masthead {
            height: 100%;
            padding: 0
        }
        .masthead .intro-body .brand-heading {
            font-size: 100px
        }
        .masthead .intro-body .intro-text {
            font-size: 22px
        }

    }
    
    .btn {
        font-family: Cabin, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: 400;
        transition: all .3s ease-in-out;
        text-transform: uppercase;
        border-radius: 0
    }
    /* ::-moz-selection {
    background: #fcfcfc;
    background: hsla(0, 0%, 100%, .2);
    text-shadow: none
}

 ::selection {
    background: #fcfcfc;
    background: hsla(0, 0%, 100%, .2);
    text-shadow: none
} */
    
     ::-moz-selection {
        background: #856404;
        background: rgba(133, 100, 4, .5);
        text-shadow: none
    }
    
     ::selection {
        background: #856404;
        background: rgba(133, 100, 4, .5);
        text-shadow: none
    }

    #contact button {
        width: 157px
    }
    
    #contact li:nth-child(3) button {
        position: relative;
        left: -4px
    }
    
    #myVideo {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
    }
    
    .btn-box {
        border: 1px solid white;
        color: white;
        background-color: #a91d28!;
        text-decoration: none!important;
    }
    
    .btn-box:hover {
        border: 1px solid white;
        background-color: rgb(235, 189, 52);
        text-decoration: none;
        color: white;
    }
    
    .profile-selectable-item {
        cursor: pointer;
        border-radius: 15px;
        transition: 0.3s;
        padding: 10px;
    }
    .profile-selectable-item:hover {
        cursor: pointer;
        border-radius: 15px;
        /* border: 2px solid gold; */
        /* background-color: gold; */
        /* background-color: rgb(235, 189, 52); */
        /* background-color: #856404; */
        background: radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42));
        transition: 0.3s;
        color: white;
        padding: 10px;
    }
    
    .button-as-link, .button-as-link:active {
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
    }

    .not-selectable:hover{
        cursor: not-allowed;
    }


    .unava-tooltip {
        position: relative;
        display: inline-block;
      }
      
      /* Tooltip text */
      .unava-tooltip .unava-tooltiptext {
        visibility: hidden;
        width: 120px;
        /* background-color: black; */
        /* background: radial-gradient(circle, rgb(255, 221, 64), rgb(162, 141, 42)); */
        background: rgb(162, 141, 42);
        color: #fff;
        /* color: black; */
        font-weight: 500;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
       
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
      }
      
      /* Show the tooltip text when you mouse over the tooltip container */
      .unava-tooltip:hover .unava-tooltiptext {
        visibility: visible;
      }
